import React, { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { NotificationsBarRendering } from '@sitecore/types/NotificationsBar';
import { NotificationBar } from '@sparky';

const NotificationsBar: FC<NotificationsBarRendering> = ({ fields: { notification, showNotificationOption } }) => {
  if (!showNotificationOption.value) return null;

  return (
    <NotificationBar
      title={notification.value.title}
      text={<RichText html={notification.value.content} />}
      variant={notification.value.variant || 'warning'}
    />
  );
};

export default NotificationsBar;
